.onboarding-sec {
  padding: 2em 2em;
  margin: auto;
}

.welcome-onboarding-img {
  max-width: 15em;
}

.onboarding-auth-footer-skip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-onboarding-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  flex-direction: column;
  text-align: center;
}

.welcome-onboarding-info-sec {
  margin-top: 1em;
}

.welcome-onboarding-info-sec .efi-primary-btn {
  text-decoration: unset
}

.skip-frame-action .efi-primary-btn {
  text-decoration: unset
}

.welcome-onboarding-info-sec h4 {
  font-weight: 600;
  color: var(--tertiary-color);
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 0.3em;
}

.welcome-onboarding-info-sec p {
  font-weight: 400;
  color: var(--quaternary-color);
  font-size: 0.85em;
  line-height: 1.8;
  margin-bottom: 1em;
}

.efi-bank-onboarding-step-wrapped {
  background: var(--light-color);
  border-radius: 12px;
  border-radius: 12px;
}

.efi-bank-onboarding-step-head {
  padding: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  border-bottom: 1px solid #E9E9E9;

}

.efi-bank-onboarding-step-head h4 {
  margin-bottom: 0;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--dark-color);
}

.onboarding-step-btn {
  background: linear-gradient(90deg, rgba(102, 131, 235, 0.005) -2.75%, rgba(102, 131, 235, 0.0405) 99.79%) !important;
  font-size: 0.95em !important;
  font-weight: 600 !important;
  padding: 1em 2em !important;
  color: var(--dark-color) !important;
  border: 1px solid #E9E9E9 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: 0.6em;
  width: 100%;
}

.onboarding-step-btn.active {
  border: 1px solid #6683EB !important;
}

.efi-bank-onboarding-step-count-frame ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.efi-bank-onboarding-step-body {
  padding: 2em;
}

.onboarding-step-btn.completed .onboarding-step-status {
  display: flex !important;
  align-items: center;
  gap: 0.6em;
}

.onboarding-step-btn .onboarding-step-status {
  display: none;
  color: #8F9899;
  font-weight: 400;
  font-size: 0.9em;
}

.onboarding-auth-header {
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.onboarding-auth-header .pretty label {
  font-size: 0.95em;
  font-weight: 600;
  color: var(--dark-color);
  font-weight: 500;
}

.onboarding-auth-header .pretty .state label:after,
.onboarding-auth-header .pretty .state label:before {
  border-radius: 20%;

}

.onboarding-auth-header .pretty.p-default input:checked~.state label:after {
  background-color: var(--button-color) !important;
}

.single-date-picker .input-group {
  flex-wrap: nowrap;
}

.gender-check-box {
  display: flex;
  align-items: center;
  gap: 1em;
}

.gender-check-box input[type='radio'] {
  /* display: none; */
}

.efi-bank-check-gender h3 {
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--dark-color);
}

.onboarding-avater {
  width: 14em;
  height: auto;
  object-fit: contain;
}

.onboarding-final-frame {
  text-align: center;
  padding: 0 4em;
}

.onboarding-inner-area {
  /* border: 1px solid #E9E9E9; */
  border-radius: 12px;
  padding: 2em;
}

/* New Feature  */
.efibank-onboarding-feature-wrapped {
  display: flex;
  /* align-items: center; */
  width: 100%;
  position: relative;
}

.efibank-onboarding-feature-step-wrap {
  background: url("../../../public/img/feature/onboarding-overlay.png") no-repeat center;
  background-size: cover;
  background-color: #121212;
  padding: 2em;
  min-height: 100vh;
  width: 30%;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.efibank-onboarding-feature-step-details {
  background: url("../../../public/img/feature/overlay-line.png") no-repeat right;
  background-size: contain;
  padding: 2em;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  position: relative;
  margin-left: 30%;
}

.feature-grid-layout {
  position: relative;
  width: 100%;
}

.efibank-onboarding-feature-step-details::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.skip-frame-action {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 2%;
  right: 50px;
  width: 100%;
  z-index: 99;
  gap: 1em;
}

.feature-grid-layout-two {
  position: relative;
  z-index: 9;
}

.onboarding-feature-step-frames-info h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--light-color);
}

.onboarding-feature-step-frames-info p {
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0;
  color: #878E96;
}

.feature-step-count {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f7fe47;
  font-size: 0.9em;
  font-weight: 600;
}

.feature-step-title p {
  margin-bottom: 0;
  color: #f4f7fe47;
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.feature-list-step-card.active .feature-step-count {
  background: var(--light-color);
}

.feature-list-step-card.active .feature-step-title p {
  color: var(--light-color);
}

.feature-list-step-card.completed .feature-step-count {
  background: #40AC78;
}

.feature-list-step-card {
  display: grid;
  grid-template-columns: 60px auto;
  align-items: center;
}

.feature-line:before {
  background-color: #f4f7fe47;
  content: "";
  display: inline-block;
  height: 40px;
  margin-left: 20px;
  margin-top: 5px;
  width: 2px;
}

.onboarding-feature-step-list {
  margin-top: 2em;
}

.onboarding-feature-step-list ul {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.feature-logo {
  /* width: 14em; */
  height: 3em;
  /* height: auto; */
  object-fit: contain;
}

.onboarding-feature-logo {
  padding-bottom: 4em;
}

.react-joyride__spotlight {
  width: auto !important;
}

.feature-grid-layout-two {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4em;
}

.illustration-avater {
  width: 20em;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.efi-bank-feature-form-avater {
  text-align: center;
}

.onboarding-auth-header p {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0;
  color: #878E96;
}

.onboarding-auth h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0.5em;
  color: var(--tertiary-color);
  white-space: nowrap;
}

.efi-bank-feature-form-avater {
  /* width: 100%; */
  /* margin-right: 5vw; */
  /* position: -webkit-sticky !important;
  position: sticky !important;
  top: 15em;
  align-self: flex-start;
  height: auto; */
}

.onboarding-auth .input-group-text {
  background: transparent !important;
}

.onboarding-auth .efi-bank-default-form .form-control {
  box-shadow: none;
  border-left: none;
  padding-left: 0;
  /* border: 1px solid #dbdbdb; */
}

.onboarding-auth .efi-bank-default-form .form-control:focus {
  border-left: none !important;
}

.onboarding-auth.billing-addres-form .efi-bank-default-form .form-control {
  box-shadow: none;
  /* border-left: none; */
  padding-left: 10px;
  border-left: 1px solid #dbdbdb;
}

.onboarding-auth.billing-addres-form .efi-bank-default-form .form-control:focus {
  border-left: 1px solid #dbdbdb;
}

.onboarding-auth .efi-bank-default-form .form-control::placeholder {
  color: #757575;
  font-size: 0.9em;
  font-weight: 400;
}

.space-tow {
  display: flex;
  align-items: center;
  gap: 1em;
}

.onboarding-auth-footer {
  /* padding-top: 2em; */
}

.onboarding-auth.billing-addres-form .efi-bank-default-form.efi-onboarding-form-after-login .form-control:focus {
  border-left: 1px solid #dbdbdb !important;
}

.onboarding-auth .efi-bank-default-form.efi-onboarding-form-after-login h3 {
  margin-bottom: 0;
}

.onboarding-auth .onboarding-auth-header .pretty .state label:before,
.onboarding-auth .onboarding-auth-header .pretty .state label:after {
  top: 0;
}

.kyc-card-wrapped {
  margin-top: 2em;
}

.head-two-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.kyc-card-frame {
  border: 1px solid #E9E9E9;
  border-radius: 12px;
  padding: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
}

.initial-load {
  width: fit-content;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 6px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}

.initial-load:before {
  content: "Initializing..."
}

@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.efi-bank-virtual-dropdown .dropdown-menu {
  padding: 0.5em;
  border: 0 !important;
  box-shadow: none !important;
}

.efi-bank-virtual-dropdown .dropdown-menu .dropdown-item:focus,
.efi-bank-virtual-dropdown .dropdown-menu .dropdown-item:hover {
  border-radius: 12px;
}

.efi-bank-virtual-dropdown .dropdown-menu .dropdown-item {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--text-color) !important;
  padding: 0.5em 1em;
}

.onboarding-kyc-manual-header-sec a {
  color: var(--tertiary-color);
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 1em;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.onboarding-kyc-manual-header-sec p {
  color: #878e96;
  font-size: .9em;
  font-weight: 400;
  margin-bottom: 0;
}

.onboarding-kyc-manual-upload-list-sec {
  border: 1px solid #00000014;
  padding: 1em;
  border-radius: 8px;
}

.onboarding-kyc-manual-upload-list-title {
  color: var(--tertiary-color);
  font-size: .9em;
  font-weight: 500;
  margin-bottom: 0;
}

.onboarding-kyc-manual-upload-list li {
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
  padding: 0.8em;
}

.onboarding-kyc-manual-upload-list {
  margin-bottom: 0;
}

.onboarding-kyc-manual-upload-list li.completed .complete-icon {
  opacity: 1;
  visibility: visible;
}

.complete-icon {
  opacity: 0;
  visibility: hidden;
}

.onboarding-kyc-manual-upload-list li.current-state {
  background-color: #F0F8FE;
  border-radius: 8px;
}

.onboarding-kyc-manual-upload-list li.current-state .onboarding-kyc-manual-upload-list-title {
  color: var(--button-color);
}

.onboarding-kyc-manual-upload-dropzone-upload-btn-sec {
  border: 1px solid #E7E8E9;
  background-color: var(--light-color);
  border-radius: 50px;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.85em;
  font-weight: 600;
}

.onboarding-kyc-manual-upload-header-sec h4 {
  font-size: 1.1em;
  color: var(--tertiary-color);
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}

.onboarding-kyc-manual-upload-dropzone-info-sec h4 {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.onboarding-kyc-manual-upload-dropzone-info-sec p {
  color: #878e96;
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0;
}

.onboarding-kyc-manual-upload-dropzone-card {
  border: 1px dashed #CCCED3;
  gap: 1.2em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.onboarding-kyc-manual-upload-item {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 1em;
  margin: 2em 0;
}

.onboarding-kyc-manual-uploaded-dropzone-upload-btn-sec {
  display: grid;
  grid-template-columns: 40px auto 40px;
  align-items: center;
  border: 1px solid #E7E8E9;
  background-color: var(--light-color);
  border-radius: 50px;
}

.onboarding-kyc-manual-uploaded-dropzone-upload-left {
  padding: 0.4em 0.5em;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f1f8ff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.onboarding-kyc-manual-uploaded-dropzone-upload-middle {
  padding: 0.4em 1em;
  color: var(--tertiary-color);
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 0;
}

.onboarding-kyc-manual-uploaded-dropzone-upload-right {
  padding: 0.4em 0.5em;
  display: flex;
  background-color: #FFF5F5;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.upload-preview-sec {
  border: 1px dashed #CCCED3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  gap: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 16px;
  background: #ca9e0a10;
}

/* Transaction page  */
.single-transaction-frame {
  background: #fff;
  padding: 1.5em;
  border-radius: 8px;
  border: 1px solid #dde1e6;
}

.single-transaction-content {
  /* padding: 1em; */
}

.single-transaction-titles {
  margin-bottom: 1.5em;
}

.single-transaction-titles h4 {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.single-transaction-titles p {
  font-size: 0.9em;
  color: var(--tertiary-color) !important;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.titles-space {
  border-bottom: 1px solid #fafafa;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.single-transaction-box {
  display: grid;
  grid-template-columns:290px auto;
  gap: 1em;
  align-items: center;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px;
  padding: 0.5em 0.5em;
}

.single-transaction-box .single-transaction-country {
  background: var(--secondary-color);
  border-radius: 8px;
  font-size: 0.85em;
}

.important-card {
  display: grid;
  grid-template-columns: 35px auto;
  align-items: center;
  background: var(--overlay-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 0.5em 0.5em;
  margin-top: 0em;
}

.important-info p {
  margin: 0 !important;
  font-size: 0.8em !important;
  color: var(--primary-color) !important;
  font-weight: 400;
}

.single-transaction-fields .form-control {
  border: 0 !important;
  /* height: 50px; */
  font-size: 1.2em;
  font-weight: 600;
  color: var(--dark-color);
  /* padding: 0!important; */
}

.efi-bank-step-authentic-card .verification-item div {
  justify-content: start;
}

.text-danger {
  font-size: 0.9em;
}

.single-transaction-fields .form-control:focus {
  box-shadow: unset !important;
  border: 0 !important;
}

.single-space {
  border-bottom: 1px solid var(--overlay-color);
  margin: 2em 0;
}

.single-transaction-fields .form-control::placeholder {
  font-size: 0.75em !important;
  font-weight: 400;
  color: #dde1e6;
}

.single-transaction-box-field .form-label {
  font-size: 0.9em;
  color: var(--secondary-color);
  font-weight: 500;
}

.single-transaction-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.single-transaction-info-card:last-child {
  margin-bottom: 0;
}

.margin-btm-sm {
  margin-bottom: 1.5em;
}

.margin-top-sm {
  margin-top: 1.5em;
}

/* .single-transaction-info-box{
  margin-bottom: 2em;
  } */
.single-transaction-info-card h4 {
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--secondary-color);
}

.single-transaction-info-card h5 {
  font-size: 0.85em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--secondary-color);
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.trans-avater {
  width: 16em;
  height: auto;
  object-fit: contain;
}

.trans-beneficiary-avater {
  width: 12em;
  height: auto;
  object-fit: contain;

}

.single-transaction-final h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0em;
}

.single-transaction-final {
  border: 1px solid #dde1e6;
  background: #fff;
  padding: 2em;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
    align-items: center;  */
  gap: 0.5em;
}

.single-avater {
  text-align: center;
}

.single-avater h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0em;
}

.currency-conversion-body .nav-link {
  color: var(--text-color) !important;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
}

.currency-conversion-body .nav-link.active {
  background: var(--overlay-color) !important;
  color: var(--primary-color) !important;

}

.currency-conversion-body {
  padding: 1.5em;
  background: var(--light-color);
  border-radius: 8px;
  min-height: calc(100vh - 125px);
}

.currency-conversion-body .nav-pills {
  margin-bottom: 2em;
}

.single-transaction-body {
  padding: 1.5em;
  background: var(--light-color);
  border-radius: 8px;
  min-height: calc(100vh - 125px);
}

.single-transaction-status {
  height: 100%;
}

.single-transaction-status ul {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.single-transaction-beneficiary {
  background: #fff;
  padding: 1.5em;
  border-radius: 8px;
  border: 1px solid #dde1e6;
}

.single-transaction-beneficiary {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  justify-content: center;
}

.single-transaction-back .back-btn {
  padding: 0 !important;
  background-color: unset !important;
  border: 0 !important;
}

.single-transaction-back h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 0;
  text-align: center;
  flex: auto;
}

.single-transaction-back {
  display: flex;
  align-items: center;
}

.single-transaction-beneficiary-select-wrap {
  padding: 1.2em;
  border-radius: 8px;
  border: 1px solid #dde1e6;
}

.single-transaction-beneficiary-select-card {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0em;
}

.single-transaction-beneficiary-select-card h4 {
  font-size: 1em;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 0;
}

.single-transaction-beneficiary-select-card .beneficiary-select-btn {
  padding: 0 !important;
  background-color: unset !important;
  border: 0 !important;
  color: var(--primary-color) !important;
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: .9em;
  font-weight: 600;
}

.single-transaction-beneficiary-select-card .beneficiary-select-btn:hover {
  color: var(--primary-color) !important;
}

.single-transaction-beneficiary-select-card .beneficiary-select-btn:focus {
  background-color: unset !important;
  border: 0 !important;
  color: var(--primary-color) !important;
}

.single-transaction-beneficiary-select-card .beneficiary-select-btn:active {
  background-color: unset !important;
  border: 0 !important;
  color: var(--primary-color) !important;
}

.single-transaction-beneficiary-view-card {
  padding: 1.2em;
  border-radius: 8px;
  border: 1px solid #dde1e6;
}

.beneficiary-view-card-titles h3 {
  font-size: 1em;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 0;
  text-align: start;
}

.beneficiary-view-card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-card-titles-info {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--dark-color);
}

.view-card-titles-amt {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--dark-color);
}

.beneficiary-fee-card-wrap {
  padding: 1.2em;
  border-radius: 8px;
  background-color: #F4F9FC;
}

.beneficiary-fee-card-frame {
  padding: 1.2em;
  border-radius: 8px;
  background-color: var(--dark-color);
  color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fee-card-discout-head {
  font-size: 0.85em;
  font-weight: 500;
  color: #A4A4A4;
}

.fee-card-discout-amt {
  font-size: 0.9em;
  font-weight: 600;
  color: #A4A4A4;
}

.beneficiary-view-card-titles,
.beneficiary-view-card-wrap {
  margin-bottom: 1em;
}

.beneficiary-fee-card-title {
  margin-bottom: 1em;
}

.beneficiary-view-card-wrap {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.fee-card-badge {
  padding: 0.1em 0.9em;
  /* font-size: 0.75em; */
  background-color: #E8F3E6;
  color: #3C8E1B;
  border: 1px solid #AECAA9;
  border-radius: 30px;
  display: inline-block;
}

.beneficiary-fee-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fee-card-info {
  font-size: 0.85em;
  font-weight: 500;
}

.fee-card-amt {
  font-size: 0.9em;
  font-weight: 600;
}

.single-transaction-beneficiary-bank {
  /* padding: 1.5em; */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px #0000000A;
  margin-top: 1.2em;
}

.beneficiary-bank-top {
  padding: 1.2em;
}

.beneficiary-bank-card-title {
  font-size: 0.9em;
  font-weight: 500;
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  color: var(--dark-color);
  margin-bottom: 1em;

}

.beneficiary-bank-card-frame {
  background-color: #ABABAB17;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.beneficiary-bank-card-frame h4 {
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 10px;
  margin-bottom: 0;
  color: var(--dark-color);
}

.bank-card-badge {
  padding: 0.4em 1em;
  font-size: 0.7em;
  font-weight: 600;
  background-color: #E8F3E6;
  color: #3C8E1B;
  border: 1px solid #AECAA9;
  border-radius: 30px;
  display: inline-block;
}

.beneficiary-bank-space {
  /* margin: 1em 0; */
  border-bottom: 1px solid #F0F0F0;
}

.beneficiary-bank-name {
  font-size: 0.9em;
  font-weight: 600;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  color: var(--dark-color);
}

.beneficiary-bank-currency {
  font-size: 0.9em;
  font-weight: 600;
  display: grid;
  grid-template-columns: 35px auto;
  align-items: center;
  color: var(--dark-color);
}

.beneficiary-bank-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2em;
}

/* .select-beneficiary-head-search .form-control::placeholder {
  font-size: 0.9em;
} */

.select-beneficiary-head-search .input-group {
  width: 25em;
}

.select-beneficiary-head-search .form-control {
  border: 1px solid #E5E8EF;
  box-shadow: none;
  border-right: 0 !important;
  background: transparent !important;
  height: 48px;
  font-size: 1em;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.select-beneficiary-head-search .search-btn {
  padding: 0 !important;
  background-color: unset !important;
  border: 0 !important;
}

.select-beneficiary-head-search .form-control:focus {
  border: 1px solid #E5E8EF;
  box-shadow: none;
  border-right: 0 !important;
  /* background: #F6FAFE !important; */
}

.select-beneficiary-modal .modal-content {
  padding-top: 0.5em;
}

.select-beneficiary-head-search .input-group-text {
  background: transparent;
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select-beneficiary-head-titles h3 {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 1.5em;
}

.select-beneficiary-head {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.select-beneficiary-head-action{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-bank-card-input {
  border: 1px solid #E5E8EF;
  border-radius: 10px;
  padding: 1em;
  cursor: pointer;
}

.select-bank-card-input-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-bank-card-bank-info {
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
}

.select-bank-card-bank-info {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--dark-color);
}

.select-bank-card-bank-name {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--dark-color);
}

.select-bank-card-bank-no {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--dark-color);
}

.select-bank-card-input {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}

.select-beneficiary-check .form-check-input:checked+.select-bank-card-input {
  border-color: var(--primary-color);
  background-color: #F3F9FF;
}

.select-beneficiary-check .form-check-input {
  display: none;
}

.select-beneficiary-check {
  padding: 0 !important;
  margin: 0 !important;
}

.select-beneficiary-body-card {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 1em;
}

.select-beneficiary-modal .modal-header {
  padding: 0;
}

.select-beneficiary-modal .modal-header .btn-close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 0.8em;
  z-index: 999;
}

.single-transaction-box-avater {
  text-align: center;
}

.single-transaction-box-field h4 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 1em;
  text-align: center;
}

.single-transaction-box-field p {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--tertiary-color);
  text-align: center;
  margin-bottom: 1.5em;
  word-break: break-word;
}

.transaction-status-card {
  padding: 1.5em;
  background: var(--light-color);
  border: 1px solid #dde1e6;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 70px auto;
  position: relative;
  align-items: center;
}

.transaction-status-card.active {
  background: var(--overlay-color);
  color: var(--primary-color);
  border: 1px solid var(--overlay-color);
}

.transaction-status-card.active .status-count {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.transaction-status-card.active .status-check svg path {
  fill: var(--primary-color);
}

.transaction-status-card .status-check svg path {
  fill: #f0f0f0;
}

.transaction-status-card.complete {
  background: var(--overlay-color);
  color: var(--primary-color);
  border: 1px solid var(--overlay-color);
}

.transaction-status-card.complete .status-count {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.transaction-status-card.complete .status-check svg path {
  fill: var(--primary-color);
}

.status-count {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde1e6;
  font-weight: 600;
}

.status-check {
  position: absolute;
  top: 10px;
  right: 10px;
}

.balance-statement-card {
  padding: 2em;
  border: 1px solid #dde1e6;
  border-radius: 8px;
}

.single-transaction-country .react-select__input {
  color: var(--light-color) !important;
}

.bal-single-space {
  margin: 1.5em 0;
  border-bottom: 1px solid #dde1e6;
  transform: scale(0.8);
}

.balance-label {
  font-size: 0.9em;
  color: var(--secondary-color);
  font-weight: 500;
  margin-bottom: 0.5em;
}

.balance-statement-card-info {
  text-align: center;
}

.balance-statement-card-info h3 {
  font-size: 1.1em;
  font-weight: 400;
  margin-bottom: 0;
}

.balance-statement-card-info h3 span {
  color: var(--primary-color);
  font-weight: 600;
}

.onboarding-business-info-head {
  display: flex;
  flex-direction: column;
}

.PhoneInputCountry select:disabled {
  opacity: 0;
}
.efi-bank-create-frame{
  gap: 1em;
  flex-direction: column;
}
.efi-bank-create-frame-info h3{
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 1em;
  color: var(--dark-color);
  text-align: center;
}
.create-account-avater{
  max-width: 15em;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.efi-bank-view-wrapped{
  padding: 2em;
}
.efi-bank-view-content-frame{
  border-radius: 12px;
  background: var(--light-color);
}
.efi-bank-view-content-head,.efi-bank-view-content-body{
  padding: 2em;
}
.efi-bank-view-content-head{
  border-bottom: 1px solid #1C1C1C14;
}
.efi-bank-head-info h3{
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0.5em;
  color: var(--dark-color);
}
.efi-bank-head-info p{
  font-size: 0.95em;
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 0.6em;
}
.efi-bank-status{
border: 1px solid #fff;
font-size: 0.85em;
font-weight: 400;
text-align: center;
padding: 0.7em 2.5em;
border-radius: 12px;
}
.efi-bank-status.active{
  border-color: #1DE39A;
  color: #1DE39A;
}
.efi-bank-status.inactive{
  border-color: #e72320;
  color: #e72320;
}
.efi-bank-view-content-head{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.grid-layout-three,.grid-layout-two{
  display: grid;
  align-items: center;
  gap: 1.5em;
}
.grid-layout-three{
  grid-template-columns: repeat(3,1fr);
}
.grid-layout-two{
  grid-template-columns: repeat(2,1fr);
}
.grid-layout-one{
display: flex;
flex-direction: column;
gap: 1em;
}
.account-details-card-title h4{
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--dark-color);
}
.account-details-card-title{
  margin-bottom: 1em;
}
.grid-layout-card h5{
  font-size: 0.85em;
  font-weight: 400;
  color:#878E96;
  margin-bottom: 0.3em;
}
.grid-layout-card p{
  font-size: 0.8em;
  font-weight: 400;
  color:var(--text-color);
  margin-bottom: 0em;
}
.efi-bank-account-details-car{
  padding: 2em;
  border-radius: 12px;
  border: 1px solid #1C1C1C14;
}

.account-card-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.account-card-row a{
 text-decoration: none;
}

.full-body-card-wrapped .bank-logo {
  width: 8em;
  margin-bottom: 1em;
}

.account-balance-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.account-balance-sec {
  display: flex;
}

.wallet-transfer-sec {
  background: #e4ffe7;
  border-radius: 1em;
  padding: 2em;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
}

.wallet-transfer-sec .form-control:focus{
  box-shadow: none;
  border: none;
}

.wallet-transfer-sec .form-control{
  font-size: 0.85em;
  font-weight: 700;
  color: var(--tertiary-color);
  line-height: 1.5;
  height: 3em;
}

.wallet-transfer-sec h3{
  font-size: 1.1em;
  color: var(--tertiary-color);
  font-weight: 600;
  margin-bottom: 0;
}

.wallet-balance-card {
  background: linear-gradient(101.19deg, #4FFFA0 0%, #A6B8EF 106.9%, #0126A9 110.32%);
  border-radius: 1em;
  padding: 1.5em;
  display: flex;
  gap: 1em;
  align-items: center;
  margin-bottom: 1em;
}

.account-balance-card {
  background: linear-gradient(101.19deg, #FFCC1F 0%, rgba(251, 72, 116, 0.78) 110.32%);
  border-radius: 1em;
  padding: 1.5em;
  display: flex;
  gap: 1em;
  align-items: center;
}

.account-balance-card h4,
.wallet-balance-card h4 {
  margin-bottom: 0;
  font-size: 1.2em;
  color: var(--light-color);
  font-weight: 600;
}

.account-balance-card p,
.wallet-balance-card p {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--light-color);
  margin-bottom: 0;
  line-height: 1.5;
}

.account-details-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-details-icon {
  height: 2em;
}

.apply-atm-icon{
  background: #F7F7FE;
  padding: 1em;
  border-radius: 50%;
}

.apply-atm-icon img{
  height: 2.5em;
}

.wallet-card-icon {
  background-color: #5ddfb0;
  padding: 0.9em;
  border-radius: 50%;
}

.wallet-card-icon {
  background-color: #5ddfb0;
  padding: 0.9em;
  border-radius: 50%;
}

.account-balance-icon{
  background-color: #fec156;
  padding: 0.9em;
  border-radius: 50%;
}

.bank-account-balance {
  display: flex;
  justify-content: space-between;
}

.bank-account-detail-text:nth-child(1) {
  margin-bottom: 1.5em;
}

.open-account-btn {
  display: flex;
  justify-content: end;
}

.account-modal h3 {
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.account-modal .modal-header {
  border-bottom: none;
}

.step-5 img {
  width: 7em;
  margin-bottom: 1em;
}

.step-modal-button {
  margin-top: 1em;
  display: flex;
  border-top: none;
  gap: 1em;
}

.step-modal-button .btn {
  width: 100%;
}

.step-2 .modal-body .create-account-modal-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: -3em; */
  gap: 1em;
}

.img-upload-container .img-upload {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.img-upload-container .img-upload .custom-file-label {
  border: 1px solid #f7996b;
  border-radius: 0.4em;
  padding: 1em;
}

.img-upload-container p {
  font-size: 0.9em;
  font-weight: 700;
  text-align: center;
}

.img-upload-container p span {
  font-size: 0.9em;
  font-weight: 400;
}

.change-password-heading {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 1em;
}
.proxy-card-input-modal .modal-body{
  padding-top: 0em!important;
}
.account-card-row .bank-logo {
  width: 5em;
}

.account-card-row .bank-logo-orange {
  width: 3em;
}

.account-card-row .card {
  height: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0.5em;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.account-card-row .open-account-btn {
  background: var(--button-color);
  border: none;
}

.account-card-row .open-account-btn:hover {
  background: var(--button-color);
}

.account-card-row .open-account-btn:active {
  background: var(--button-color) !important;
}

.single-date-picker .rmdp-input {
  height: 45px;
  border-right: none;
  border-radius: 8px 0 0 8px;
  margin: 0;
  min-width: 100%;
  border-color: #dee2e6 !important;
  font-size: 0.8em;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
}

.single-date-picker .rmdp-week-day {
  color: var(--button-color);
}

.single-date-picker .rmdp-input:focus {
  box-shadow: none;
  border-right: 0!important;
}
.single-date-picker  .input-group-text:focus{
border-left: 0!important;
}
.single-date-picker .rmdp-input:focus-within {
  border-right: 0!important;
}

.single-date-picker .input-group-text {
  height: 45px;
  border-left: 0!important;
  background: none;
}

.single-date-picker .input-group-text:focus-within {
  border-left: 0!important;
}

.single-date-picker .rmdp-day.rmdp-selected span:not(.highlight) {
  background:linear-gradient(90deg, #0194ff 0%, #0b68b8 100%);
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}
.rmdp-container {
  width: 100%!important;
}

/* All New Style  */
.efi-bank-create-account-wrapped{
  /* padding: 2em; */
  background: var(--light-color);
  border-radius: 12px;
  /* margin: 2em; */
  padding-top:7.5em!important;
}
.efi-feature-transation-table-head{
  padding: 2em 0;
}
.efi-feature-account-balance-frame{
  padding: 1.5em;
  border-radius: 12px;
  background: var(--overlay-color);
  display: grid;       
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
}
.efi-feature-account-balance-status{
  /* display: flex;
  align-items: center;
  justify-content: end; */
  margin-bottom: 0em;
}
.ac-status-card{
  background: var(--light-color);
  padding: 0.2em .4em;
  font-size: 0.85em;
  font-weight: 400;
  color: var(--primary-color);
  text-align: center;
  border-radius: 20px;
}
.ac-status-card.active{
  color: var(--button-color);
}
.efi-feature-account-balance-info p{
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: var(--text-color);
}
.efi-feature-account-balance-info h2{
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 0em;
  color: var(--dark-color);
}
.efi-feature-four-layout{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1em;
}
.efi-ac-details-card p{
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: var(--text-color);
}
.efi-ac-details-card h3{
  font-size: 0.85em;
  font-weight: 600;
  margin-bottom: 0em;
  color: var(--dark-color);
  word-break: break-word;
  line-height: 1.5;
}
.efi-feature-ac-full-details-layout{
  border: 1px solid #8291B129;
  padding: 1.5em;
  background: var(--light-color);
  border-radius: 12px;
}
.efi-feature-layout-title{
  margin-bottom: 1em;
}
.efi-feature-layout-title h2{
  font-size: 1em;
  font-weight: 400;
color: var(--text-color);
margin-bottom: 0;  
}
.efi-feature-layout-two{
  display: grid; 
  grid-template-rows: 1fr;
  grid-template-columns:320px auto;
  gap: 1em;
  /* height: 100%; */
}
.download-btn{
  background: linear-gradient(180deg, #FFFFFF 39.57%, rgb(249 246 246));
  font-size: 0.95em !important;
  font-weight: 500 !important;
  padding: 0.7em 2em !important;
  color: var(--dark-color) !important;
  border: 1px solid #E9E9E9 !important;
  display: flex !important;
  align-items: center;
  gap: 0.6em;
}
.efi-transation-search .form-control:focus, .card-sendmoney-modal .efi-bank-default-form .form-control:focus{
  border: 1px solid #E5E8EF;
  box-shadow: none;
  border-right: 0 !important;
  /* background: #F6FAFE !important; */
}
.efi-transation-search .form-control {
  border: 1px solid #E5E8EF;
  box-shadow: none;
  border-right: 0 !important;
  background: transparent !important;
  height: 45px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.efi-transation-search .input-group-text{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.card-sendmoney-modal .efi-bank-default-form .form-control {
  border: 1px solid #E5E8EF;
  box-shadow: none;
  background: transparent !important;
  height: 45px;
}
.card-sendmoney-modal .efi-bank-default-form textarea.form-control,.card-sendmoney-modal .efi-bank-default-form textarea.form-control{
  border: 1px solid #E5E8EF!important;
  height: auto!important;
  border-right: 1px solid #E5E8EF!important;

}
.efi-transation-search .input-group-text, .card-sendmoney-modal .efi-bank-default-form .input-group-text {
  background: transparent;
  border-left: none;
}

.efi-transation-search .form-control,  .card-sendmoney-modal .efi-bank-default-form .form-control::placeholder{
  font-size:0.9em;
}
.efi-feature-transation-search-frame{
  display: flex;
  align-items: center;
  gap: 1em;
}
.efi-transation-search .input-group{
  min-width: 17em;
}
.layout-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.efi-feature-transation-title h2{
  font-size: 1em;
  margin-bottom: 0;
  color: var(--dark-color);
  font-weight: 600;
}
.efi-trade-card{
  width: 2.6em;
  height: 2.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px #F1F1F1;

}
.color-green{
  color: #009F5B;
}
.color-red{
  color: #EB6363;
}
.efi-bank-card-details-frame{
  background: var(--light-color);
  border-radius: 12px;
}
.efi-bank-card-details-frame-head{
  padding: 1em;
  border-bottom: 1px solid #1C1C1C14;
}
.default-primary-outline{
  background: var(--overlay-color)!important;
  color: var(--primary-color)!important;
  border:  0!important;
  padding: 0.7em 2em!important;
  display: inline-block;
  border-radius: 12px!important;
  position: relative;
  font-size: 0.95em!important;
  font-weight: 600!important;
  text-decoration: none!important;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.default-primary-outline span{
  background: -webkit-linear-gradient(90deg, #0194ff 0%, #0b68b8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .default-primary-outline:hover,
  .default-primary-outline:focus{
    background: var(--primary-gradient) !important;
  }


  .default-primary-outline:hover span,
  .default-primary-outline:focus span{
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: var(--light-color);
  }

.card-details-head-action,.efi-bank-card-action{
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: flex-end;
}
.copy-btn{
  background: transparent !important;
  padding: 0 !important;
  border: unset !important;
}
.efi-bank-card-frame-content h4{
 font-size: 1em;
 font-weight: 600;
 margin-bottom: 0;
 color: var(--dark-color);
 letter-spacing: 5px;
}
.efi-bank-card-frame-content{

}

.efi-bank-card-frame{
  /* padding: 2em; */
  border-radius: 12px;
  /* border: 1px solid #EBF0F7; */
}
.efi-bank-card-details-frame-content{
  padding: 2em;
}
.grid-layout-card p{
display: flex;
align-items: center;
gap: 0.5em;
}
.efi-bank-card-frame-footer{
align-items: flex-end!important;
}
.efi-bank-card-action button{
  background: var(--light-color)!important;
padding: 0.4em !important;
border: unset !important;
width: 2em;
height: 2em;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.efi-bank-card-frame-head{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.efi-bank-card-frame-content{
}
.efi-bank-virtual-badges{
  padding: 0.4em 1em;
  border-radius: 30px;
  text-align: center;
  font-size: 0.75em;
  font-weight: 500;
  background: var(--light-color);
  color: var(--dark-color);
}
.efi-bank-badges.active{
  background: #EBF8F2;
  color: #009F5B;

}
.efi-bank-badges{
  background:     var(--light-color);
  color: #8291B1;
  padding: 0.4em 1em;
  border-radius: 30px;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;
  /* border: 1px solid #00000012 */
}
.efi-bank-card-frmae-icons{
  background:var(--light-color);
  width: 2.5em;
  height: 2.5em;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.profile-action-btn {
  background: linear-gradient(180deg, #FFFFFF 39.57%, rgb(249 246 246));
  font-size: 0.95em !important;
  font-weight: 500 !important;
  padding: 0.7em 2em !important;
  color: var(--dark-color) !important;
  border: 1px solid #E9E9E9 !important;
  display: flex !important;
  align-items: center;
  gap: 0.6em;
}
.efi-bank-card-frame{
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em;
}
.efi-bank-virtual-layout-card h5{
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: var(--text-color);
}
.efi-bank-virtual-layout-card p{
  font-size: 0.75em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--dark-color);
}
.efi-bank-card-details-title h2{
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0em;
  color: var(--dark-color);
}
.card-details-card-modal .modal-body{
  padding: 0!important;
}
.efi-card-agree-info-frame h3{
  font-size: 0.95em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--dark-color);
}
.efi-card-agree-info-frame :where( h3, h4, p),.efi-card-agree-avater-frame{
  text-align: center;
}
.efi-card-agree-info-frame h4{
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--dark-color);
}
.efi-card-agree-info-frame p{
font-size: 0.9em;
font-weight: 400;
margin-bottom: 0;
color: var(--text-color);
text-transform: capitalize;
}
.efi-card-agree-info-frame{
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.efi-card-agree-action-frame .submit-button {
  background: var(--primary-gradient);
  font-size: 0.95em;
  font-weight: 600;
  padding: 0.8em 2em !important;
  border: none;
  text-transform: capitalize;
}
.efi-card-agree-action-frame .overlay-button {
  background: -webkit-linear-gradient(90deg, #0194ff 0%, #0b68b8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  font-size: 0.95em;
  font-weight: 600;
  padding: 0.8em 2em !important;
  border: none;
  text-transform: capitalize;
}
.efi-card-agree-action-frame,.efi-card-agree-frame{
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.efi-card-agree-avater{
  max-width: 12em;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}
.card-sendmoney-modal .modal-body{
  padding-top: 0em!important;
}
.pre-footer-modal{
  padding-top: 1em;
}
.pre-footer-modal p{
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--text-color);
}
.pre-footer-modal p span{
  color: var(--dark-color);
}
.efi-feature-transation-title span{
  background: var(--overlay-color);
  color: var(--button-color);
  font-size: 0.75em;
  font-weight: 500;
  padding: 0.5em;
  border-radius: 4px;
  color: var(--primary-color);
}
.efi-feature-header-frame {
  padding-bottom: 1.5em;
}
.efi-recipient-set-pin-frame .efi-bank-step-info {
  padding: 1em 0;
}
.efi-recipient-set-pin-frame .efi-bank-step-info div{
  gap: 2em;
}
.virtual-card-bg{
  background-image: url("../../../public/img/feature/virtual.png");
  background-repeat: repeat;
  background-size: cover;
}
.physical-card-bg{
  background-image: url("../../../public/img/feature/physical.png");
  background-repeat: repeat;
  background-size: cover;
}
.efi-setting-tab .nav-link{
  color: var(--text-color)!important;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
}
.efi-setting-tab .nav-link:focus-visible{
  box-shadow: none!important;
}
.efi-setting-tab .nav-link.active{
  background: var(--overlay-color)!important;
  color: var(--primary-color) !important;
}
.efi-setting-feature-wrapped{
  padding: 2em;
  background: var(--light-color);
  border-radius: 8px;
}
.efi-setting-feature-wrapped .tab-content{
  min-height: calc(100vh - 300px);
}
.efi-setting-password{
  border: 1px solid #E9E9E9;
  padding: 2em;
  background: var(--light-color);
  border-radius: 8px;
}
.efi-setting-password .input-group .input-group-text{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.efi-bank-step-authentication-content{
  padding: 2em 0;
}
.onboarding-auth-titles{
max-width: 599px;
}
.efi-bank-step-authentic-card .efi-bank-step-info div{
  gap: 1em;
}
.efi-onboarding-process-card-info p{
  margin-bottom: 0;
  color: var(--text-color);
  font-size: 0.9em;
  font-weight: 400;
}
.efi-transation-search .search-btn ,.date-cancel{
  background: transparent!important;
  padding: 0!important;
  border: 0!important;
}
.efi-data-input{
  flex-wrap: unset!important;
}
.efi-data-input .input-group-text{
  background: transparent;
  border-left: 0!important;
  border: 0;
}
.efi-data-input{
  border: 1px solid #E5E8EF!important;
  border-radius: 8px;
}
.efi-data-input .rmdp-input{
border: 0!important;
font-size:0.9em;
}
.efi-data-input .rmdp-input{
  height: 40px!important;
  min-width: 15em;
  padding-left: 10px;
}

.rmdp-container .icon.icon-tabler.icon-tabler-calendar-event{
  width: 32px;
}
.date-cancel{
  margin-right: 6px;
}
.new-billings-pagination-sec #react-paginate ul{
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.new-billings-pagination-sec #react-paginate ul li {
  padding: 0!important;
}
.new-billings-pagination-sec #react-paginate ul li.active-page a{
 background: var(--primary-gradient);
 color: var(--light-color);
 border: 0!important;
}
.new-billings-pagination-sec #react-paginate ul li a{
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 1.2em !important;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--primary-gradient);
}
.new-billings-pagination-sec #react-paginate ul li:first-child{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.new-billings-pagination-sec #react-paginate ul li:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.header-search .form{
  display: flex;
  gap: 1em;
}
.card-avater{
  width: 3.5em;
  height: 3.5em;
  object-fit: contain;
}
.services-card-wrapped{
  display: grid;
  align-items: center;
  gap: 2em;
  grid-template-columns: 200px 200px;
}
.services-card-wrapped .form-check-label{
  width: 100%;
}
.services-card-wrapped input[type='radio'] {
  display: none;
}
.services-card-wrapped .form-check-label .form-check-input:checked +  .service-card-custome{
  border-color:var(--primary-color);
  padding: 1em;
}
.service-card-custome {
  border:1px dashed #e7e7e7;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.check-tick{
  position: absolute;
  top: 2px;
  right: 10px;
  display: none;
}
.services-card-wrapped .form-check-label .form-check-input:checked +  .service-card-custome .check-tick{
  display: inline-block;
}
.no-data-full-wrappe{
  min-height: calc(100vh - 230px);
  display: grid;
  /* justify-content: center; */
  width: 100%;
  align-content: center;
}

.efi-data-input .rmdp-input:focus{
  box-shadow: none!important;
  outline: none!important;
}

.efi-transation-search .form-control .input-group-text+.input-group-text{
  border-left: 0!important;
}

.efi-transation-search .border-right-zero{
  border-right: 0;
}

.efi-submit-btn-overlay{
  min-width: unset!important;
}


/* View Page style Start */
.quote-wrapped,.beneficiary-wrapped{
  background: var(--light-color);
  border-radius: 12px;
  padding: 1.5em;
}
.quote-frame-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status-badges{
  padding: 0.6em 2em;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.9em;
  font-weight: 400;
}
.status-badges.success{
color: #20bf55;
background: rgb(32 191 85 / 10%);
}
.status-badges.fail{
color: #ff4a4a;
background:rgb(255 74 74 / 10%);
}
.quote-frame-titles{
  display: flex;
  align-items: center;
  gap: 1em;
}
.quote-frame-titles h2{
  font-size: 1.1em;
  margin-bottom: 0;
  font-weight: 500;
  color: var(--dark-color);
}
.quote-info-card{
  background: var(--overlay-color);
  padding: 1.5em;
  border-radius: 12px;
  height: 100%;
  border: 1px solid var(--primary-color);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 1.5em!important;
  /* display: flex;
  flex-direction: column;
  gap: 2em; */
}

/* .quote-info-card .quote-card:nth-child(even){
  text-align: right;
}

.quote-info-card .quote-card:nth-child(even) p, .quote-info-card .quote-card:nth-child(even) p{
  justify-content: flex-end;
} */

.efi-feature-transation-title p{
  font-size: 0.9em;
  color: var(--gray-color);
  margin-top: 0.5em;
  font-weight: 400;
}

.beneficiary-from-box,.recipient-from-box{
  padding: 1em;
}
.quote-space{
  margin: 1.5em 0;
  border-bottom: 1px solid #efefef;
}
.status-type p{
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 0.95em;
  font-weight: 300;
  color: var(--text-color);
  text-align: center;
}
.status-type p span,.quote-card p span,.quote-overlay-info p span{
  font-weight: 600;
  color: var(--dark-color)!important;
}
.quote-card h5,.quote-overlay-info h5{
  font-size: 0.95em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: var(--dark-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40ch;
}
.quote-card p,.quote-overlay-info p{
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--text-color);
  display: flex;
  gap: 0.5em;
  align-items:center;
}
.card-part{
  border-bottom: 1px solid #fff;
  margin: 1.5em 0;
}
.quote-overlay-frame{
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 1.5em;
}
.quote-overlay-card{
  display: grid;
  grid-template-columns: repeat(2 , 1fr) ;
  gap: 2em;
}
.quote-overlay-info p span +.amount-per{
  color: var(--primary-color)!important;
  font-size: 1em!important;
  font-weight: 600!important;
  margin-left: 0.2em;
}
.quote-overlay-title h3{
  font-size: 1.1em;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--primary-color);
}
/* View Page style End */


/* Beneficiary info  style start */
.benefic-info-head{
  display: flex;
  align-items: center;
  gap: 1em;
  background:#f8f8f8;
  padding: 1.5em;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.benefic-info-head h2{
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--primary-color);
}
.benefic-layout{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1.5em;
}
.layout-one-grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  gap: 1.5em;
}
.layout-three-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 1.5em;
}
.layout-two-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 1.5em;
}
.benefic-info-card p{
  color: var(--text-color);
  font-size: 0.85em;
  margin-bottom: 0.4em;
  font-weight: 400;
}
.benefic-info-card h4{
  font-size: 0.95em;
  font-weight: 500;
  color: var(--dark-color);
  margin-bottom: 0;
}
.benefic-full-card{
  padding: 1.5em;
}
.benefic-space{
  margin: 1em 0;
  border: 1px dashed #f0f0f0;
}
.benefic-full-card-title h3{
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--dark-color);
}
.benefic-full-card-title{
  margin-bottom: 1.5em;
}
.border-frame{
  border-top: 0;
  border: 1px solid #f0f0f0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.blur-gray{
  background: #efefef;
  border-radius: 12px;
  height: 100%;
}
.chart-container .chartjs-legend {
  margin: 20em; /* Adjust as needed */
}


/* Beneficiary View Style start */
.beneficiary-info-card{
  padding: 1.5em;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-radius: 12px;
  /* border: 1px solid #eeeeee; */
  
}
.tab-content-frame .beneficiary-info-card{
  padding:0em 0;
}

.tab-content-frame{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: var(--light-color);
  padding: 1.5em;
  margin-top: 2em;
  border-radius: 12px;
}
.beneficiary-info-head h3{
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--primary-color);
}
.beneficiary-child-info-head h3{
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--primary-color);
}
.beneficiary-child-frame-info{
  padding: 1em 0;
  padding-bottom: 0;
}

.beneficiary-frame-head{
  margin-bottom: 2em;
}
.benefi-info-list ul{
   display: flex;
  flex-direction: column;
  /* display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr); */
  gap: 1.5em;
  list-style: auto;
  margin-bottom: 0;
}

.transaction-copy-btn-sec .copy-btn:hover svg path{
  fill: var(--primary-color);
}

.benefi-info-list ul .benefi-info-card p+p{
  margin-top: 0.3em;
}
.code-frame h5{
  font-size: 0.85em;
  font-weight: 300;
  margin-bottom: 0;
  color: var(--dark-color);
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
  gap: 0.5em;
  background: #efefef;
  border-radius: 8px;
  padding: 0.5em 1em;
  width: 100%;
}
.code-frame h5 span{
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.beneficiary-code-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  flex-wrap: wrap;
}
.beneficiary-code-sec .payment-initiated,
.beneficiary-code-sec .payment-processing,
.beneficiary-code-sec .payment-completed,
.beneficiary-code-sec .payment-failed,
.beneficiary-code-sec .payment-expired{
max-width:unset;
-webkit-box-orient: vertical;
/* display: -webkit-box; */
overflow: hidden;
text-overflow: ellipsis;
word-break: break-all;
-webkit-line-clamp: 1;
white-space: normal!important;
}
.code-status{
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 0.9em;
  border-radius: 8px;
  padding: 0.5em 2em;
  border: 1px solid #0194ff;
}
.code-status.success{
  border: 1px solid #20bf55;
  color: #20bf55;
  background: rgb(32 191 85 / 10%);
  }
.code-status.fail{
  border: 1px solid #ff4a4a;
  color: #ff4a4a;
  background:rgb(255 74 74 / 10%);
  }
  .benefi-info-icons{
    border-radius: 4px;
    border: 1px solid #eeeeee;
    padding: 8px;
  }

  .code-frame{
    width: fit-content;
  }
.benefi-info-card h4,.code-frame h4{
  font-size: 0.95em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: var(--dark-color);
}
.benefi-info-card p{
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 0em;
  color: var(--dark-color);
}
.card-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}
.card-grid__4{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5em;
}
.card-grid__5{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5em;
}
.card-grid__2{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5em;
}
.card-grid__3{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5em;
}
.card-grid__5 div:first-child{
  grid-area: 1 / 1 / 2 / 3;
}
.benefici-space{
  margin: 0.5em 0;
  border-bottom: 1px solid #eeeeee;
}
.beneficiary-info-head{
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.beneficiary-code-card{
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.border-space{
position: relative;
}
.border-space::after{
content: ' ';
position: absolute;
background-color: #eeeeee;
width: 1px;
height: 100%;
right: 10px;
transform: scale(0.9);
top:0;
}
.back-gray{
  background: rgb(229 244 255 / 40%);
}
/* Beneficiary View Style end */
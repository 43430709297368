

.expense-transition-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.expense-transition-modal .expense-transition-modal-heading {
  text-align: center;
}

.expense-transition-modal .expense-transition-modal-heading img {
  width: 4em;
  margin-bottom: 1em;
}

.expense-transition-modal .expense-transition-modal-heading h3 {
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 1em;
}

.expense-transition-modal .modal-body {
  padding-top: 0 !important;
}
.expense-transition-modal .transaction-modal-right img {
  width: 6.5em;
}

.expense-transition-modal .transaction-modal-right {
  display: flex;
  justify-content: flex-end;
}

.expense-transition-modal .download-transaction-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.expense-transition-modal h4 {
  font-size: 0.85em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-top: 0.6em;
  line-height: 1.5;
}

.expense-transition-modal h6 {
  font-size: 0.75em;
  font-weight: 500;
  color: var(--heading-color);
  margin-bottom: 0;
}

.expense-transition-modal .modal-footer {
  border-top: none;
}

.user-access-team .nav-pills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  padding: 0 !important;
}

.user-access-team .nav-link {
  flex-direction: none;
  align-items: none;
  justify-content: none;
}

.user-access-team .nav-item:nth-child(1) .nav-link {
  background: #d7ffd4;
}

.user-access-team .nav-item:nth-child(2) .nav-link {
  background: #f3edff;
}

.user-access-team .nav-item:nth-child(3) .nav-link {
  background: #ffd5d5;
}

.user-access-team .nav-item:nth-child(4) .nav-link {
  background: #feffb5;
}

.user-access-team-sec {
  padding: 1em 0;
  display: flex;
  align-items: center !important;
  gap: 1em;
  width: 100%;
}

.user-access-team-sec .user-access-icon {
  padding: 0.9em;
  border-radius: 50%;
}

.user-access-team .nav-item:nth-child(1) .user-access-icon {
  background-color: #e9ffe8;
}

.user-access-team .nav-item:nth-child(2) .user-access-icon {
  background: #e9e4f4 !important;
}

.user-access-team .nav-item:nth-child(3) .user-access-icon {
  background: #ffe9e9 !important;
}

.user-access-team .nav-item:nth-child(4) .user-access-icon {
  background: #F8F974 !important;
}

.user-access-team .nav-link.active {
  border-bottom: 2px solid #000;
}

.user-access-team-sec h4 {
  margin-bottom: 0 !important;
  font-size: 1.2em !important;
  color: var(--tertiary-color) !important;
  font-weight: 600 !important;
}

.user-access-team-sec p {
  font-size: 0.85em !important;
  font-weight: 500 !important;
  color: var(--tertiary-color) !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  line-height: 1.5 !important;
  text-decoration: none;
}

.user-access-team-sec {
  padding: 1em 0;
  display: flex;
  gap: 1em;
  width: 100%;
}

.user-access-team-info {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}


.setting-full-body-wrapped{
   padding: 2em 2em;
   display: flex;
   flex-direction: column;
   gap: 1em;
   margin-top:5.5em;
}

.setting-full-card{
    border-radius: 12px;
    background: var(--light-color);
    padding: 1.5em;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-top: 2em;
}

.setting-card-left-frame .setting-card-info h3{
    font-size: 1em;
    font-weight: 600;
    color: var(--dark-color);
    margin-bottom: 0.3em;
}

.setting-card-left-frame .setting-card-info p{
    font-size: 0.8em;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 0;
}

.setting-card-icons{
    width: 2em;
    height: 2em;
}

.setting-icons{
   width: 100%; 
}

.setting-card-left-frame{
    display: grid;
    grid-template-columns: 46px auto;
    align-items: center;
}

.setting-card-right-frame button{
    background: unset!important;
    border: none!important;
    padding: 0!important;
}

.privacy-card-left-frame{
    grid-template-columns: 46px auto;
    align-items: center;
    color: var(--heading-color);
}

.privacy-policy h3{
    margin-bottom: .8em;
    font-size: 1.2em;
    color: var(--tertiary-color);
    font-weight: 600;
}

.privacy-policy p,.privacy-policy li{
    font-size: 0.9em;
    font-weight: 400;
    color:var(--text-color);
    margin-bottom: 0.3em;
}
.efi-bank-billing-address-frame{
    padding: 1.5em;
    border-radius: 8px;
    background: var(--light-color);
    border: 1px solid #E9E9E9;
}
.efi-bank-billing-address-frame-title h3{
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--dark-color);
}
.efi-bank-billing-address-frame-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.efi-bank-title-actions{
    display: flex;
    align-items: center;
    gap: 1em;
}
.profile-card-header {
   display: flex;
   justify-content: space-between;
}

.profile-card-header .button-without-background {
   border: 1px solid var(--button-color);
   font-size: .8em;
   padding: 0.8em 2.5em;
   font-weight: 600;
   text-transform: uppercase;
   background: none;
   color: var(--button-color);
}

.profile-card-header .button-without-background:active{
   border: 1px solid var(--button-color) !important;
   color: var(--button-color) !important;
   background: none!important;
}

.profile-card-header h3{
   font-size: 1.1em;
   color: var(--tertiary-color);
   font-weight: 600;
   margin-bottom: 0;
}

.profile-card-header .button-without-background:hover {
   background: none;
   color: var(--button-color);
}

.full-body-card-wrapped .profile-card-img{
   display: flex;
   align-items: center;
   gap: .8em;
}

.full-body-card-wrapped .profile-card-img .profile-user-img {
   height: 5em;
   width: 5em;
}
.full-body-card-wrapped .profile-card-img  p{
   margin-top: 1em;
   background: linear-gradient(134.4deg, rgba(72, 52, 137, 0.9) -5%, rgba(224, 83, 152, 0.9) 101.04%);
   -webkit-background-clip: text;
   color: transparent;
   font-size: 0.9em;
   font-weight: 600;
}

 .card-form-country-code-input {
   border: 1px solid #DBDBDB;
   padding: 0.6em 0.8em;
   border-radius: 12px;
   height: 45px;
}

.profile-detail h3{
   font-size: 1.1em;
   color: var(--tertiary-color);
   font-weight: 600;
   margin-bottom: 1.5em;
}

 .card-form-country-code-input .PhoneInputInput{
   border: none;
   font-size: .9em;
   color: var(--heading-color);
}

 .card-form-country-code-input .PhoneInputInput:focus-visible {
   outline: none;
}

.full-body-card-wrapped .profile-information-detail-form .card-from-country {
   font-size: .9em;
   color: var(--heading-color);
   padding: 14px;
}

.full-body-card-wrapped .profile-information-detail-form .card-from-country:focus {
   border: 1px solid var(--button-color);
   box-shadow: none;
}

/* New Style   */

.profile-full-frame-wrapped{
   background: var(--light-color);
   border-radius: 12px;
   padding: 2em;
   border: 1px solid #E9E9E9;
}
.profile-user-card-info,.profile-user-picture-sec,.profile-basic-details-frame{
   display: flex;
   flex-direction: column;
   gap: 1em;
}
.profile-user-label,.profile-user-card-frame,.profile-basic-details-card{
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 3em;
}
.profile-user-label h3{
   font-size:0.9em;
   font-weight: 600;
   color: var(--dark-color);
   margin-bottom: 0;
}
.profile-user-label a{
   font-size:0.85em;
   font-weight: 600;
   color: var(--button-color);
   margin-bottom: 0;
   /* background: -webkit-linear-gradient(90deg, #0194ff 0%, #0b68b8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-decoration: none!important;
}
.profile-user-picture-sec h2{
font-size: 0.95em;
font-weight: 600;
   color: var(--dark-color);
   margin-bottom: 0;
}
.profile-user-img{
   width: 6em;
   height: 6em;
   object-fit: contain;
}
.profile-basic-details-card :where(h4,h5,a){
   font-size: 0.85em;
   font-weight: 400;
   margin-bottom: 0;
}
.profile-basic-details-card h4{
   color: var(--gray-color);
   display: grid;
   grid-template-columns: 35px auto;
   align-items: center;
}
   .profile-basic-details-card h5{
      color: var(--dark-color);
      font-weight: 600;
   }
      .profile-basic-details-card a{
         color: var(--button-color);
      
      }
.profile-basic-details-frame{
padding-top: 1em;
}
.efi-bank-profile-action{
   padding-top: 1.5em;
}
.profile-action-btn{
   background:linear-gradient(180deg, #FFFFFF 39.57%, rgb(249 246 246));
   font-size: 0.95em !important;
   font-weight: 500 !important;
   padding: 0.7em 2em !important;
   color: var(--dark-color)!important;
   border: 1px solid #E9E9E9!important;
   display: flex !important;
   align-items: center;
   gap: 0.6em;
}
.space-line{
   margin: 1em 0;
   border: 1px solid rgb(216 216 216 / 20%);
}
.efi-bank-profile-setting-card{
   background: var(--light-color);
   border-radius: 12px;
   padding: 2em;
   display: flex;
   flex-direction: column;
   gap: 1em;
   border: 1px solid #E9E9E9;
}
.efi-bank-profile-setting-info h3{
   font-size: 1em;
   font-weight: 500;
   margin-bottom: 0.7em;
   color: var(--dark-color);
}
.efi-bank-profile-setting-info p{
   font-size: 0.85em;
   font-weight: 400;
   margin-bottom: 0em;
   color: #878E96;
}
.efi-tab-featur-card{
   margin-top: 2em;
}
.profile-basic-details-card button{
   width: auto!important;
}
.efi-bank-virtual-dropdown .spinner-loader{
/* background: #0194ff!important; */
border-right-color: #0194ff!important;
}
.details-card-frame{
   width: 3.5em!important;
   height: 3.5em!important;
}
.dropdown-item.active, .dropdown-item:active {
   color: var(--bs-dropdown-link-active-color);
   text-decoration: none;
   background-color: unset!important;
 }
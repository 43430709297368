.login-page-img {
  width: 90%;
  height: auto;
  max-width: 100%;
}

.logo-auth-img {
  max-width: 12em;
  margin-bottom: 2em;
}

.service-type-radio-btn {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding: .375rem .75rem;
  border-radius: var(--bs-border-radius);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.efi-bank-auth-form .efi-bank-auth-form-box .input-group {
  /* height: 45px; */
  border: 1px solid var(--bs-border-color) !important;
  background: var(--light-color) !important;
  border-radius: 8px;
}

.efi-bank-auth-form .efi-bank-auth-form-box .input-group-error.input-group {
  border-color: #e34f4f;
}

.register-phone-input.input-group-error .PhoneInput {
  border-bottom-color: #e34f4f;
}

.efi-bank-auth-form .efi-bank-auth-form-box .form-control {
  box-shadow: none;
  border-left: none;
  padding-left: 0;
  background: var(--light-color) !important;
  border: 0;
  line-height: 1.8;
  border-radius: 8px;
  /* font-size: 0.9em;
  font-weight: 300; */
}

.efi-bank-auth-form .efi-bank-auth-form-box .input-group {
  /* margin-bottom: 1em; */
}

.efi-bank-auth-form .efi-bank-auth-form-box .form-control::placeholder {
  color: #878E96;
  font-size: 0.9em;
  font-weight: 400;
}

.efi-bank-auth-form .efi-bank-auth-form-box .form-control:focus {
  border: 0px solid var(--secondary-color);
  box-shadow: none;
  border-left: 0 !important;
  background: var(--light-color) !important;
}

.efi-bank-auth-form .efi-bank-auth-form-box .input-group:focus-within .form-control,
.efi-bank-auth-form .efi-bank-auth-form-box .input-group:focus-within .input-group-text {
  box-shadow: none;
}

.efi-bank-auth-form .efi-bank-auth-form-box .input-group-text {
  background: var(--light-color);
  border: 0;
  border-right: none;
  border-radius: 8px;
}

.efi-bank-auth-form .efi-bank-auth-form-box .password-icon {
  background: var(--light-color) !important;
  border-right: 0;
  border-left: none;
}

.efi-bank-auth-form .efi-bank-auth-form-box .password-input {
  border-right: none;
}

.errorMsg {
  font-size: 0.9em;
  color: #e34f4f;
}

.login-page-box .errorMsg {
  /* font-size: 0.9em;
  color: red; */
  font-size: 12px;
  position: absolute;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  top: -20px;
  right: -15px;
  z-index: 99;
  height: 25px;
  line-height: 1;
  background-color: #e34f4f;
  color: #fff;
  font-weight: 400;
  display: inline-block;
  padding: 6px 8px;
}

.login-page-box .errorMsg::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 6px 6px 0;
  border-color: transparent #e34f4f;
  display: block;
  width: 0;
  z-index: 99;
  bottom: -6px;
  right: 1.2em;
}

.login-page-box .efi-bank-auth-input-group {
  position: relative;
}

.auth-option-gap .auth-option-gap-line {
  width: 42%;
  background: #e0e0e0;
  height: 1px;
  position: relative;
  top: 0.7em;
}

.auth-option-gap .auth-option-text {
  width: 30%;
  font-size: 0.8em;
}

.auth-option-gap {
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}

.efi-bank-auth-form .efi-bank-auth-form-box .continue-login-button span {
  margin: 10px;
}

.efi-bank-auth-form .efi-bank-auth-form-box .continue-login-button:focus {
  border-left: 1px solid #dde1e6 !important;
}

.efi-bank-auth-form .efi-bank-auth-form-box .efi-auth-forget-password {
  display: flex;
  justify-content: end;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  color: var(--button-color);
}

.efi-bank-auth-form .efi-bank-auth-form-box .efi-auth-forget-password:hover {
  color: var(--hover-color);
}

.efi-bank-auth-form .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  gap: 0.5em;
  justify-content: center;
}

.efi-bank-auth-form .efi-bank-auth-form-box .nsm7Bb-HzV7m-LgbsSe {
  display: flex;
  text-decoration: none;
  border-radius: 12px;
  border: 1px solid #dde1e6;
  padding: 0.7em;
  margin-top: 1em;
  font-size: 0.8em;
  font-weight: 500;
  color: #373a3c;
  padding-left: 2.4em;
  justify-content: center;
  gap: 1em;
  align-items: center;
}

.efi-bank-auth-form .nsm7Bb-HzV7m-LgbsSe:hover .nsm7Bb-HzV7m-LgbsSe-MJoBVe,
.efi-bank-auth-form .nsm7Bb-HzV7m-LgbsSe:focus .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background: transparent;
}

.efi-bank-auth-form-box .nsm7Bb-HzV7m-LgbsSe .LgbsSe-Bz112c {
  position: relative !important;
  /* left: 8.5em; */
}

.efi-bank-auth-form-box .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  flex: unset;
  font-size: 1.15em;
  font-weight: 500;
  color: #373a3c;
  font-family: "Outfit", sans-serif !important;
}

.efi-bank-auth-form .efi-bank-auth-form-box .continue-login-button {
  display: flex;
  text-decoration: none;
  border-radius: 12px;
  border: 1px solid #dde1e6;
  padding: 0.7em;
  margin-top: 1em;
  font-size: 0.9em;
  font-weight: 500;
  color: #373a3c;
  padding-left: 2.4em;
  justify-content: center;
  gap: 1em;
  align-items: center;
}

.efi-bank-auth-form .efi-bank-auth-form-box .continue-login-button span {
  margin: 10px;
}


.efi-bank-auth-form .efi-bank-auth-form-box .auth-heading {
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0.8em;
  color: var(--tertiary-color);
}

.efi-auth-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding-top: 2em;
}

.efi-auth-signin .default-primary-btn {
  width: 100%;
}

.efi-auth-signin p {
  margin-bottom: 0;
}

.efi-auth-signin .sign-up {
  color: var(--button-color);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.efi-auth-signin .sign-up:hover {
  color: var(--hover-color);
}

.efi-auth-signin .sign-up a {
  color: var(--button-color);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.efi-auth-signin .sign-up a:hover {
  color: var(--hover-color);
}

.static-link-sec a:hover {
  color: var(--hover-color);
}

.register-phone-input .PhoneInput {
  display: flex;
  align-items: center;
  height: 42px;
  border: 0;
  border-radius: var(--bs-border-radius);
  width: 25em;
  border-radius: 0.37rem;
  width: 100%;
  padding: 0 18px 0 14px;
  font-size: 0.9em;
  font-weight: 500;
  background: var(--light-color) !important;
}

.register-phone-input .PhoneInput:focus input {
  border: none !important;
  box-shadow: none !important;
}

.register-phone-input .PhoneInput input {
  border: none;
  outline: none;
  background: transparent;
  height: 45px;
  color: #212529;
}

.profile-tab .PhoneInput input {
  border: none;
  outline: none;
  background: transparent;
}

.sign-up-verification .auth-form-sec {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.resend-otp-btn {
  background: transparent !important;
  padding: 0 !important;
  text-decoration: underline !important;
  color: var(--primary-color) !important;
  border: 0 !important;
  font-size: 0.95em !important;
  font-weight: 400;
}

.resend-otp-btn:hover {
  color: var(--hover-color) !important;
}

.resend-otp-btn:active {
  color: var(--hover-color) !important;
}

.resend-otp-btn:focus {
  color: var(--hover-color) !important;
}

.verification-btn-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  flex-direction: column;
}

.verification-btn-sec p {
  margin-bottom: 0;
  font-size: 0.85em;
  font-weight: 400;
  color: #767676;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.verification-item div {
  gap: 1em;
  justify-content: center;
}

.verification-item h6 {
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 1.5em;
}


.service-type-radio-btn h4 {
  font-size: 0.9em;
  color: var(--tertiary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.static-link-sec {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 45px auto;
  align-items: center;
  justify-content: center;
}

.static-link-sec p {
  margin-bottom: 0;
  font-size: 0.9em;
}

.page-loader {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #0194ff 90%, #fff);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
  }

  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
  }

  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
  }
}

.efi-feature-transation-search-frame a {
  font-size: 0.9em;
  font-weight: 500;
  color: #1f73b7;
}

.efi-onboarding-process-card {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  gap: 1em;
  /* margin-left: 2em; */
}

.efi-onboarding-process-card-info h3 {
  font-size: 0.95em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: var(--dark-color);
}

.delete-contact {
  border: 1px solid #E31D1D !important;
  color: #E31D1D !important;
  background: transparent !important;
  padding: 0.7em 2.5em !important;
}

.efi-bank-head-info h3 {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.login-left-card {
  min-height: 100vh;
  background: url("../../../public/img/feature/auth/auth-bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  position: -webkit-sticky;
  top: 0;
  position: sticky;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #020a22;
}

.login-page-form-box {
  min-height: 100vh;
  /* background: url("../../../public/img/feature/auth/auth-bg-right.png") no-repeat center; */
  background: var(--light-color);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2em 0;
  height: 100%;
}

/* .login-page-form-box::before{
  position: absolute;
  content:"";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255,255,255,0.5);
  z-index: 1;
} */

.efi-bank-auth-form {
  position: relative;
  z-index: 9;
}

.pretty.p-default input:checked~.state label:after {
  background-color: var(--button-color) !important;
}

.pretty.p-default input:checked~.state label::before {
  border-color: var(--button-color);
}

.efi-bank-auth-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1.5em;
}

.efi-bank-auth-title h3 {
  font-size: 1.6em;
  margin-bottom: 0;
  color: var(--dark-color);
  font-weight: 600;
  text-align: center;
}

.efi-bank-auth-title p {
  font-size: 0.9em;
  margin-bottom: 0;
  color: #878E96;
  font-weight: 400;
  text-align: center;
}

.pretty.p-default input:checked~.state label:after {
  /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" fill="%23ffffff" opacity="1" data-original="%23000000" class=""></path></g></svg>')!important; */
  background-position: center;
  background-size: contain;
  background-color: var(--button-color);
}

.login-auth-logo {
  max-width: 12em;
  height: auto;
  object-fit: contain;
}

.login-auth-avater {
  max-width: 25em;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.login-auth-middle-frame {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.login-auth-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}

.login-auth-middle-frame .login-auth-info h2 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--light-color);
}

.login-auth-middle-frame .login-auth-info p,
.carousel-content p {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0;
  color: #878E96;
  text-align: center;
  max-width: 400px;
  margin: auto;
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.carousel-content {
  padding-bottom: 1em;
}

.carousel-content h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 1em;
  color: var(--light-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8em;
}

.carousel-indicators {
  margin-bottom: -1rem !important;
}

.carousel-indicators [data-bs-target] {
  width: 0.5em !important;
  height: 0.5em !important;
  border-radius: 50%;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}

.carousel-indicators [data-bs-target].active {
  width: 3em !important;
  height: 0.5em !important;
  border-radius: 20px;
  overflow: hidden;
}

.efi-auth-extra-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gender-check-box .pretty input:checked~.state.p-primary-o label:before,
.gender-check-box .pretty.p-toggle .state.p-primary-o label:before {
  border-color: var(--button-color);
}

.gender-check-box .pretty.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after {
  background-color: var(--button-color) !important;
}

.tostiy-frame-card {
  padding: 1.5em;
  border-radius: 12px;
  color: #fff;
  display: flex;
  gap: 2em;
  justify-content: space-between;
  max-width: 600px;
  position: fixed;
  right: 5%;
  top: 15%;
  z-index: 999;
}

.tostiy-action-wrapped {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5em;
}

.tostiy-frame-card.faile {
  background: #ed6262;
}

.tostiy-frame-card.success {
  background: #00a300;
}

.tostiy-frame-card .efi-onboarding-process-card-info h3,
.tostiy-frame-card .efi-onboarding-process-card-info p {
  color: var(--light-color);
}

.tostiy-action-wrapped button {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
}

.tostiy-badges {
  background: rgb(255 255 255 / 20%);
  padding: 0.5em 1em;
  border-radius: 12px;
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;

}

.tostiy-frame-card .efi-onboarding-process-card {
  grid-template-columns: 70px auto !important;
}

.tostiy-frame-card .efi-onboarding-process-card-bar .CircularProgressbar .CircularProgressbar-text {
  fill: var(--dark-color);
  font-size: 1.3em;
  font-weight: 600;
}

.static-link-sec .pretty.p-switch input:checked~.state:before,
.head-two-space .pretty.p-switch input:checked~.state:before {
  border-color: #00a300 !important;
  background: #00a300 !important;
}

.static-link-sec .pretty.p-switch input:checked~.state label:after,
.head-two-space .pretty.p-switch input:checked~.state label:after {
  background-color: var(--light-color) !important;
}

.services-card-wrapped .form-check span {
  font-size: 0.85em;
  font-weight: 500;
  margin-bottom: 0.5em;
  display: inline-block;
  color: #707070;
}

.user-dropdown-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.logout-avater {
  text-align: center;
}

.kyc-card-frame-info p {
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: var(--text-color);
}

.kyc-card-frame-info h3 {
  font-size: 0.95em;
  font-weight: 600;
  margin-bottom: 0em;
  color: var(--dark-color);
}

.step-avater-frame-img {
  max-width: 12em;
  margin: 0 auto;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.two-step-avater-frame-img {
  text-align: center;
  margin-bottom: 1em;
}

.efi-action-head {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-right: 1.5em;
}

.efi-data-input .rmdp-day.rmdp-today span {
  /*  */
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.efi-datePicker-btn-cancel {
  background: var(--overlay-color) !important;
  color: var(--primary-color) !important;
  padding: 0.7em 2em !important;
  border: none !important;
  font-size: 0.95em !important;
  font-weight: 600 !important;
  width: 100%;
}

.efi-datePicker-btn {
  background: var(--primary-color) !important;
  color: var(--light-color) !important;
  padding: 0.7em 2em !important;
  display: inline-block;
  border-radius: 12px;
  border: 0 !important;
  position: relative;
  font-size: 0.95em !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  width: 100%;
}

.profile-complete-process .process-space-bar {
  display: grid !important;
  grid-template-columns: 200px auto;
  align-items: center;
  gap: 1em;
}

.account-modal .modal-body,
.delete-account-modal .modal-body,
.change-password-modal .modal-body {
  padding-top: 0 !important;
}

.welcome-onboarding-info-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-fit {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rote {
  transform: rotate(180deg);
}

.lay-btn {
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;
}

.new-control {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-right: 0 !important;
}

.new-control+span {
  background: transparent !important;
}

.new-control+span .spinner-loader {
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #efefef;
  border-right-color: #0194ff;
  animation: l2 1s infinite linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-auth-footer button,
.onboarding-auth-footer a,
.efi-transation-action button {
  min-width: 130px;
}

.login-page-form-box .row {
  max-width: 750px;
  margin: auto;
}

.modal-popup-icon {
  max-width: 5em;
}

.change-password-modal .change-password-heading {
  text-align: center;
}

.modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.otp-frame-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-bottom: 1.5em;
}

.otp-frame-title h2 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--dark-color);
  text-align: center;
  margin-bottom: 0em;
}

.otp-frame-title p {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 0em;
}

.otp-frame-title img {
  width: 3.5em;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.verification-btn-sec .form-modal-footer {
  width: 100%;
}

.otp-validation-model .modal-body {
  padding-top: 0 !important;
}

.contact-created-modal .modal-body {
  padding-top: 0 !important;
}

.business-info-wrapped {
  background: var(--light-color);
  padding: 2em;
  border-radius: 12px;
}

.business-modal p {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.5em;
}


/* New dashboard Style  */
.new-onboarding {
  background: var(--light-color);
  border-radius: 12px;
  padding: 1.5em;
  display: flex;
  gap: 2em;
}

.new-onboarding__text {
  display: flex;
  align-items: center;
  gap: 1em;
}

.new-onboarding__text p {
  margin-bottom: 0;
  font-size: 0.9em;
  font-weight: 400;
  color: var(--text-color);
}

.new-onboarding__text p span {
  font-weight: 800;
  color: var(--secondary-color);
}

.new-onboarding__image {
  position: relative;

}

.onboarding_image__avater {
  max-width: 10em;
  object-fit: contain;
  height: auto;
  /* position: absolute;
  bottom: -16px; */
}

.graph-header__right .nav-pills {
  background: #f7f8f8;
  border-radius: 35px;
  padding: 8px;
}

.graph-header__right .nav-pills .nav-item .nav-link {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--secondary-color) !important;
  border-radius: 35px;
  padding: 6px 12px;
  line-height: 1;
}

.graph-header__right .nav-pills .nav-item .nav-link.active {
  background-color: var(--light-color) !important;
  color: var(--primary-color) !important;
}

.graph-header__right {
  display: flex;
  align-items: center;
  gap: 1em;
}

.recipient-card__recived__icons {
  width: 2.5em;
  height: 2.5em;
  background: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em;
}

.recipient-card__info h5 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.3em;
}

.recipient-card__info p {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 0em;
}

.recipient-card {
  gap: 1em;
}

.padding-skeleton-loader {
  padding: 0 1em;
}

.dashed-table__table {
  background: var(--light-color);
  padding: 1.5em;
  border-radius: 8px;
}

/* .dashed-table__table table{
  border-collapse: separate;
  border-spacing: 0 0.8em;
  padding: 0;
} */

.dashed-table__table table :where(tr td, tr th) {
  font-size: 0.8em;
  font-weight: 500;
  vertical-align: middle;
  padding: 1.5em !important;
  /* border-bottom: 8px solid #f7f8f8 !important; */
  margin-bottom: 1em;
}

.dashed-table__table table tr th {
  background: #FAFAFC !important;
  color: var(--text-color) !important;
}

.dashed-table__table table tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.dashed-table__table table tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.recipient-faile,
.recipient-success {

  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  font-size: 0.95em;
  font-weight: 500;
  gap: 1em;
  justify-content: center;
  padding: 6px 14px;
}

.recipient-faile {
  background: #FD820917;
}

.recipient-success {
  background: #E6F4F0;
}

.dashed-table__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2em;
}

.dashed-table__title h3,
.dashed-easy__title h3 {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}

.dashed-table {
  margin-top: 2em;
}

.dashed-easy-wrapped {
  background: var(--light-color);
  padding: 2em;
  border-radius: 12px;
}

.dashed-easy__form .form-control::placeholder {
  color: #D8DBDC !important;
}

.dashed-easy__form :where(.form-control, .input-group-text) {
  border: 0 !important;
  background: transparent !important;
  height: 45px;
}

.dashed-easy__form .form-control:focus {
  border: 0 !important;
  box-shadow: unset !important;

}

.dashed-easy__form .input-group,
.dashed-easy__form textarea.form-control:focus {

  border: 1px solid #EBEDF2 !important;
}

.dashed-easy__form .input-group,
.dashed-easy__form textarea {
  border: 1px solid #EBEDF2 !important;
  border-radius: 12px;
}

.canvasjs-chart-credit {
  display: none !important;
}

.grid-frame {
  display: flex;

  /* grid-template-columns: repeat(3, 1fr); */
  gap: 1em;
}

.dashboard-right-frame-card {
  /* gap: 1em;
  display: flex;
  align-items: flex-start; */
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  gap: 1em;
}

.dashboard-right-frame-view-card-top-sec {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 1em;
}

.dashboard-right-frame-view-card-bottom-sec {
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

.view-card-frame .dashboard-right-frame-view-card-top-sec .view-card:nth-child(1) {
  border: 1px solid var(--primary-color);
  background: var(--overlay-color);
}

.view-card-frame .dashboard-right-frame-view-card-top-sec .view-card:nth-child(2) {
  border: 1px solid #67BCA2;
  background: #ECF9EE;
}

.view-card-frame .dashboard-right-frame-view-card-top-sec .view-card:nth-child(3) {
  border: 1px solid #FF8181;
  background: #FFEBEB;
}

.view-card {
  padding: 1.5em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
}

.view-card__info p {
  font-size: 0.9em;
  margin-bottom: 0.8em;
  font-weight: 400;
  color: var(--secondary-color);
  /* display: flex;
  align-items: center;
  gap: 1em; */
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
}

.view-card__info h3 {
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--secondary-color);
}

.view-card__icons {
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: var(--light-color);
  border-radius: 8px;
}

.graph-card {
  padding: 1.5em;
}

.dashed-card {
  background: url("../../../public/img/dash-card-bg.png") no-repeat center;
  background-size: cover;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
}

.dashed-card__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dashed-card__bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}


.card__top__left p {
  color: var(--text-color) !important;
  font-size: 0.9em;
  font-weight: 300;
  margin-bottom: 0;
}

.card__top__left h4 span {
  color: var(--light-color) !important;
  font-size: 0.7em !important;
  font-weight: 400;
}

.card__top__left h6 {
  font-size: 0.8em !important;
  font-weight: 400;
  color: var(--text-color) !important;
  margin-bottom: 1.2em;
}

.card__bottom__left {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
}

.card__bottom__left span {
  color: var(--text-color) !important;
  font-size: 0.75em !important;
  font-weight: 400;
  margin-bottom: 0;
}

.card__top__left h4 {
  color: var(--light-color) !important;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
}

.card__top__left-value__sec {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.card__bottom__left h4 {
  color: var(--light-color) !important;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0;
}

.card__bottom__right h4 {
  color: var(--light-color) !important;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

.dashed-card__middle h3 {
  color: var(--light-color) !important;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0;
  letter-spacing: 6px;
}

.dashed-view-card {
  margin-bottom: 1.5em;
}

/* Dashboard CSS */

.dashboard-top-sec-layout {
  display: grid;
  grid-template-columns: 550px auto;
  gap: 1em;
}

.dashboard-account-card {
  background: var(--light-color);
  border-radius: 8px;
  padding: 1.5em;
}

.dashboard-account-card-header-sec h3 {
  font-size: 1.1em;
  color: var(--secondary-color);
  font-weight: 600;
  margin-bottom: 0em;
}

.dashboard-account-card-header-sec p {
  font-size: 0.9em;
  margin-bottom: 0em;
  font-weight: 400;
  color: var(--secondary-color);
}

.dashboard-account-body-top-sec h4 {
  font-size: 0.9em;
  color: var(--secondary-color);
  font-weight: 600;
  margin-bottom: 0.5em;
}

.dashboard-account-body-top-sec p {
  font-size: 0.8em;
  margin-bottom: 0em;
  font-weight: 400;
  color: var(--secondary-color);
}

.dashboard-account-body-middle-sec h4 {
  font-size: 0.9em;
  color: var(--secondary-color);
  font-weight: 600;
  margin-bottom: 0.5em;
}

.dashboard-account-body-middle-sec p {
  font-size: 0.8em;
  margin-bottom: 0em;
  font-weight: 400;
  color: var(--secondary-color);
}

.dashboard-account-body-bottom-sec h4 {
  font-size: 0.9em;
  color: var(--secondary-color);
  font-weight: 600;
  margin-bottom: 0.5em;
}

.dashboard-account-body-bottom-sec p {
  font-size: 1.4em;
  margin-bottom: 0em;
  font-weight: 800;
  color: var(--secondary-color);
}

.dashboard-account-body-bottom-sec p span {
  font-weight: 400;
  color: var(--text-color);
  font-size: 0.7em;
}

.dashboard-account-card-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-account-body-top-sec {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  margin-bottom: 1.5em;
}

.dashboard-account-body-top-right-sec {
  text-align: right;
}

.dashboard-account-body-middle-sec {
  margin-bottom: 0em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-account-body-middle-card:last-child {
  text-align: right;
}

p.active-badge {
  font-size: 0.8em;
  font-weight: 500;
  padding: 0.6em 2em;
  text-align: center;
  border-radius: 20px;
  color: #30A75F;
  background: #58D07312;
}

p.inactive-badge {
  font-size: 0.8em;
  font-weight: 500;
  padding: 0.6em 2em;
  text-align: center;
  border-radius: 20px;
  color: #EFAA42;
  background: #EFAA4212;
}

.dashboard-account-card-header-left-sec {
  display: flex;
  align-items: center;
  gap: 1em;
}
.change-password-form-heading {
  display: grid;
  justify-content: center;
}
.change-password-form-heading .switch-button{
  width: 200px;
}
.efi-bank-default-form .password-icon {
  background: none;
}
.efi-bank-default-form .password-input {
  border-right: none;
}

.efi-bank-default-form .form-control::placeholder{
  font-size:0.9em;
}

.password-input .change-password-input {
  display: none !important;
  border-right: none;
}
.efi-bank-default-form .change-password-footer{
  display: flex;  
  justify-content: end;
  padding-top: 1.5em;
}
.modal-wrapped p{
  margin-bottom: 1em;
  font-size: 0.9em;
  color: var(--tertiary-color);
  font-weight: 600;
}
.card-set-pin{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5em;
}
.card-set-pin input[type="text"] {
  width: 45px !important;
  height: 45px !important;
  border-radius: 8px !important;
  border: 1px solid #9D9D9D !important;
  background: linear-gradient(112.54deg, rgba(1, 1, 1, 0.05) 10.29%, rgba(255, 255, 255, 0) 100.35%) !important;
  font-size: 1.2em;
  font-weight: 500;
}
.card-set-pin p{
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom:0!important;
  color: #9D9D9D;
  text-align: center;
}


.payment-sec .nav-pills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}
.payment-sec .nav-link {
  border-radius: 12px;
  padding: 1.5em;
  display: flex !important;
  justify-content: start !important;
  flex-direction: column;
  background-color: none;
}



.payment-sec .nav-pills .nav-link.active{
  border-bottom: 4px solid #353751;
  background-color: none !important;
}

.payment-sec .nav-link h4 {
  font-size: 0.9em;
  color: var(--light-color);
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0;
}

.navgation-card-icon img{
  background: #FFFFFF40;
  padding: .6em;
  border-radius: 12px;
  width: 3.5em;
}

.payment-sec .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--heading-color) !important;
}

.full-body-card-wrapped
  .payment-detail-card
  .card-with-icon
  .payment-detail-card-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.full-body-card-wrapped .payment-detail-card .card-with-icon h4 {
  font-size: 1em;
  text-align: center;
}

.full-body-card-wrapped .payment-detail-table {
  border-radius: 12px;
  background: var(--light-color);
  padding: 1.5em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.payment-table-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
  gap: 1em;
}

.payment-table-header h3 {
  font-size: 1.1em;
  color: var(--tertiary-color);
  font-weight: 600;
  margin-bottom: 0!important;
}

.payment-table-header .payment-table-header-right {
  /* grid-template-columns: repeat(2, 1fr);
  display: grid; */
  gap: 1rem;
  display: flex;
  align-items: center;

}

.payment-table-header-right .dropdown-export-btn .dropdown-toggle{
  /* background: var(--button-color);
  font-size: 0.8em;
  font-weight: 600;
  padding: 0.7em 2em !important;
  text-transform: uppercase;
  border: none;
  gap: .3em;
  display: flex;   */
  background: var(--button-color)!important;
  color: var(--light-color)!important;
  padding: 0.7em 2em!important;
  display: flex!important;
  align-items: center;
  gap: 0.3em;
  border-radius: 12px;
  border: 0 !important;
  position: relative;
  font-size: 0.85em!important;
  font-weight: 600!important;
  text-decoration: none!important;
}

.payment-table-header-right .dropdown-export-btn span{
  font-size: 0.8em;
  color: #6e6d8e;;
  font-weight: 500;
  margin-left: .5em;
}

.payment-table-header-right .dropdown-export-btn .dropdown-item{
  margin-bottom: .5em;
}

.payment-table-header-right .dropdown-export-btn .dropdown-item:focus{
  background:none;
}
.payment-table-header-right .dropdown-export-btn .dropdown-item:hover{
  background:none;
}


.payment-table-header-right .dropdown-export-btn .dropdown-toggle:hover {
  background: var(--button-color);
}

.payment-table-header-right .dropdown-export-btn .dropdown-toggle:active {
  background: var(--button-color) !important;
}

.payment-table-header-right .dropdown-export-btn .dropdown-toggle::after{
 display: none;
}

.common-table {
  width: 100%;
  padding: 1em 2em;
  border-collapse: collapse;
}

.common-table .common-table-header th {
  background: #FAFAFC;
  font-weight: 500;
  font-size: 0.85em;
  vertical-align: middle;
  padding: 1em!important;
  color: var(--text-color);
  border-color: #e3e3e330!important;
  border-bottom: 0!important;
  /* text-align: center; */
}

.common-table .common-table-header th:first-child{
  border-top-left-radius: 8px;
}

.common-table .common-table-header th:last-child{
  border-top-right-radius: 8px;
  text-align: center;
}
.common-table .common-table-body tr td:last-child{
  text-align: -webkit-center;
}


/* .common-table .common-table-body tr {
  border: 1px solid #e3e3e330;
} */
.common-table .common-table-body tr:last-child{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.common-search-bar .form-control {
  box-shadow: none;
  border-left: none;
  height: 38px;
}

.payment-search-bar .form-control::placeholder {
  color: #8F9899;
}

.payment-search-bar .input-group-text {
  background: none;
  border-right: 0!important;
  border: 1px solid #DBDBDB;
}

.payment-search-bar .input-group .form-control{
  border-left: 0!important;
  padding-left: 0!important;
}

.payment-search-bar .form-control:focus {
  border: 1px solid #DBDBDB;
  box-shadow: none;
}

.payment-search-bar .input-group:focus-within .form-control,
.payment-search-bar .input-group:focus-within .input-group-text{
  border: 1px solid #DBDBDB;
  box-shadow: none;
}


.export-data-btn {
  border: none !important;
  display: flex!important;
  align-items: center;
  gap: 0.3em;
  background: var(--button-color)!important;
  color: var(--light-color)!important;
  padding: 0.8em 2em!important;
  border-radius: 12px;
  border: 0 !important;
  position: relative;
  font-size: 0.95em!important;
  font-weight: 600!important;
  text-decoration: none!important;
}

.export-data-btn:hover {
  background: var(--button-color);
}

.export-data-btn:active {
  background: var(--button-color) !important;
}

.payament-form {
  display: flex;
  gap: 5em;
  justify-content: center;
}

.payament-form .payament-form-input {
  width: 40%;
}

.payament-form .payament-form-input .form-control {
  margin-bottom: 2em;
}

.payament-form .payament-form-input .form-label {
  font-size: 1em;
  color: var(--input-label-color);
  font-weight: 500;
}

.payament-form input {
  font-size: 0.9em;
  color: var(--heading-color);
  padding: 1em;
}

.payament-form .payament-form-input.form-control:focus {
  border: 1px solid var(--button-color);
  box-shadow: none;
}

.payament-form .payament-form-input .form-control:focus-within {
  border: 1px solid var(--button-color);
  box-shadow: none;
}

.payament-form .payament-form-input .payment-form-footer,
.payment-form-footer {
  display: flex;
  justify-content: end;
  gap: 1em;
  margin-top: 1em;
}

.payament-form-input .payment-form-footer .form-modal-footer {
  display: flex;
  gap: 1em;
}

.create-beneficiary-form-heading {
  display: grid;
  justify-content: center;
}

.received-payment .common-table-header th {
  padding: 1em 0.2em !important ;
  text-align: center;
}

.received-payment .common-table-body td {
  padding: 1em;
  text-align: center;
}

.money-transfer-modal .modal-header {
  flex-direction: column;
  border-bottom: none;
}

.money-transfer-modal h3 {
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-top: 1em;
}
.money-transfer-modal .modal-body {
  margin-top: -2em;
}
.step-3 .step-3-from {
  display: flex;
  gap: 2em;
}
.money-transfer-modal .modal-body {
  margin-top: -2em;
}

.step-1 .modal-header,
.step-5 .modal-header {
  border-bottom: none;
}

.open-account-modal-heading,
.open-account-modal-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.open-account-modal-heading h3{
  font-size: 1.2em;
  font-weight: 600;
  color: var(--dark-color);
}
.modal-header{
  border-bottom: 0!important;
}
.money-transfer-modal h3 {
  font-weight: 600;
}

.money-transfer-modal .bank-logo-orange {
  width: 3em;
}

.money-transfer-modal .form-label {
  font-weight: 600;
  margin-top: 0.5em;
}

.money-transfer-modal .form-control:focus {
  border: 1px solid #DBDBDB;
  box-shadow: none;
}

.money-transfer-modal .form-select:focus {
  border: 1px solid #DBDBDB;
  box-shadow: none;
}

.money-transfer-modal .input-group:focus-within .form-control,
.money-transfer-modal .input-group:focus-within .input-group-text {
  border: 1px solid #DBDBDB;
  box-shadow: none;
}

.step-modal-button {
  margin-top: 1em;
  display: flex;
  border-top: none;
  gap: 2em;
}

.step-modal-button .btn {
  width: 100%;
}

.step-2 .modal-body .create-account-modal-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: -3em; */
  gap: 1em;
}

.step-3 .img-upload-container .img-upload {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.step-3 .img-upload-container .img-upload .custom-file-label {
  border: 1px solid #f7996b;
  border-radius: 0.4em;
  padding: 1em;
}

.step-3 .img-upload-container p {
  font-size: 0.9em;
  font-weight: 700;
  text-align: center;
}

.step-3 .img-upload-container p span {
  font-size: 0.9em;
  font-weight: 400;
}

.step-3 .date-of-birth {
  border: 1px solid #dbdbdb;
  display: flex;
  height: 2.4em;
  border-radius: 12px;
  width: 100% !important;
  padding: 0.4em 0.7em;
}

.step-3 .date-of-birth:focus-visible {
  outline: none;
}

.step-5 .dubai-bank-logo {
  width: 8em;
  margin-bottom: 1em;
}

.payment-body-sec .nav-pills {
  display: flex !important;
}

.common-table{
  /* margin-top: 1.5em; */
  white-space: nowrap;
}

.common-table td {
  box-shadow: none;
  font-weight: 500;
  font-size: 0.85em;
  word-break: break-word;
  vertical-align: middle;
  color: #252525;
  padding: 1em!important;
  /* box-shadow: 0px 1px 0px 0px #0E0A481A!important; */
  border-color: #e3e3e330!important;
  /* text-align: center; */
  /* border-radius: 12px; */
}

.balance-sheet-flex{
  display: flex;
  align-items: center;
  gap: 1em;
}

.contact-list-card-area{
  background: var(--light-color);
  border-radius: 12px;
  padding: 1.5em;
}
.dropdown-toggle::after{
  display: none!important;
}
.common-table-body .dropdown-toggle{
padding: 0!important;
background: transparent!important;
border: 0!important;
}
.dropdown-item{
  display: flex!important;
  align-items: center;
  gap: 0.5em;
  color: #1a1a1abf!important;
}
.delete-logo-avater{
  width: 4em;
  height: auto;
  object-fit: contain;
}
.open-account-modal-info{
  margin-bottom: 1em;
}
.open-account-modal-info p{
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--text-color);
}

.qr-avater-framr{
  border: 1px solid var(--dark-color);
  padding: 0.4em;
  border-radius: 12px;
  height: 6em;
  width: 6em;
  object-fit: contain;
  margin: 0 auto;
}
.qr-avater{
width: 100%;
}
.efi-bank-step-authentication-head h3{
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 1em;
  color: var(--dark-color);
  text-align: center;

}
.efi-bank-step-info input[type="text"]{
  width: 45px!important;
  height: 45px!important;
  border-radius: 4px!important;
  border: 1px solid #9D9D9D!important;
  background: linear-gradient(112.54deg, rgba(1, 1, 1, 0.05) 10.29%, rgba(255, 255, 255, 0) 100.35%)!important;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom:1em;
}
.sign-up-verification-input input[type="text"]{
  width: 45px!important;
  height: 45px!important;
  border-radius: 4px!important;
  border: 1px solid #9D9D9D!important;
  background:var(--light-color)!important;
  font-size: 1.2em;
  font-weight: 500;

}
.verification-btn-sec-action{
  display: flex;
  align-items: center;
  gap: 1em;
}
.efi-bank-step-count{
  width: 32px;
  height: 32px;
  background: #1A1A1A26;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--dark-color);
  border-radius: 50%;
}
.efi-bank-step-info p{
  font-size: 0.9em;
  font-weight: 400;
  color: #767676;
  margin-bottom: 1em;
}
.efi-bank-step-authentic-card .efi-bank-default-form  p{
  font-size: 0.9em;
  font-weight: 400;
  color: #767676;
  margin-bottom: 1em;
}
.efi-bank-step-authentic-card{
  display: grid;
  grid-template-columns: 50px auto;
  align-items: start;
}
.efi-bank-step-info h5{
  font-size: 1.1em;
  font-weight: 500;
  color: var(--dark-color);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.efi-bank-step-info h5 span{
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.efi-bank-step-info button{
  background: transparent!important;
  padding: 0!important;
  border: unset!important;
}
.efi-bank-step-authentication-content ul,.efi-bank-step-authentication-wrapped{
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* margin-bottom: 0; */
}
.efi-bank-step-authentication-wrapped{
  gap: 1.5em;
}
.efi-bank-step-authentication-wrapped .efi-bank-default-form .form-modal-footer{
  /* align-items: end!important; */
  justify-content: flex-end!important;
}

.badges{
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.4em 1em;
  text-align: center;
  border-radius: 8px;
}
.badges.success{
 color:#30A75F ;
 background:#58D07312 ;
}
.badges.faile{
  color: #FF5433;
 background:#FF2A0012 ;
}
.badges.pending{
  color: #EFAA42;
 background:#EFAA4212 ;
}

.payment-initiated{
  background-color: #EFF7FF;
  border-radius: 8px;
  padding: 0.5em 1.5em;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color:#5C9EEB;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap:0.5em;
  max-width: 250px;
  justify-content: center;
}

.payment-completed{
  background-color: #EAFFEC;
  border-radius: 8px;
  padding: 0.5em 1.5em;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color:#197E23;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap:0.5em;
  max-width: 250px;
  justify-content: center;
}

.payment-failed{
  background-color:#FF6A6A20;
  border-radius: 8px;
  padding: 0.5em 1.5em;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color:#FF6A6A;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap:0.5em;
  max-width: 250px;
  justify-content: center;
}

.payment-processing{
  background-color:#FF8A0030;
  border-radius: 8px;
  padding: 0.5em 1.5em;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color:#FF8A00;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap:0.5em;
  max-width: 250px;
  justify-content: center;
}

.payment-expired{
  background-color:#2d2c2b10;
  border-radius: 8px;
  padding: 0.5em 1.5em;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color:#595856;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap:0.5em;
  max-width: 250px;
  justify-content: center;
}

.settlement-status-cell {
  display: flex;
  align-items: center;
  gap: 2em;
}